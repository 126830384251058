

import React, { useEffect } from 'react';
import './home.css';
import lotusImage from '../../assets/lotus.png';
import sobre from '../../assets/img-sobre.png';
import imgSobre from '../../assets/title-sobre.png'
import whatsapp from '../../assets/whats.png'
import missao from '../../assets/img-missao.png'
import visao from '../../assets/img-visao.png'
import servicos1 from '../../assets/servicos1.png'
import servicos2 from '../../assets/servicos2.png'
import servicos3 from '../../assets/servicos3.png'
import servicos4 from '../../assets/servicos4.png'
import servicos5 from '../../assets/servicos5.png'
import imgServicos from '../../assets/title-servicos.png'
import terceirizar from '../../assets/title-terceirizar.png'
import imgTerceirizar from '../../assets/img-terceirizar.png'
import email from '../../assets/email.png'
import localizacao from '../../assets/localizacao.png'
import telefone from '../../assets/telefone.png'
import insta from '../../assets/insta.png'
import footer from '../../assets/Lotus4.png'




export default function Home() {

    return (
        <div className="container">
            <div className='banner'>
                <img src={lotusImage} alt='home Lotus' />
            </div>
         
            
            <div className="social-icons">
            <a className='button-conosco' href="http://api.whatsapp.com/send?phone=5561993754523" target="_blank" rel="noopener noreferrer"><strong>FALE CONOSCO</strong></a>
           
            </div>
    
            <div className='sobre-nos'> 
                <img src={imgSobre} alt='title sobre' className='img-title img-title-centralizado' />
                <div className='info-container'>
                <div className='image-container animate-up'>
                <img src={sobre} alt='sobre nos' className='img-sobre' />
                    </div>

                    <div className='text-button-container'>
                        <div className='content'>
                            <p>Somos uma equipe focada em simplificar e otimizar o processo de faturamento médico e odontológico. Combinando a expertise técnica e uma abordagem centrada no cliente, nós buscamos a excelência, visando uma entrega clara com plano de ação para aumento do seu faturamento.
                            </p>
                        </div>
                        <a className='button-sobre' href="http://api.whatsapp.com/send?phone=5561993754523" target="_blank" rel="noopener noreferrer"><strong>ENVIE UMA MENSAGEM</strong></a>
                    </div>
                </div>
            </div>

            <div className='missao-valores'>
                <div className='img-missao'>
                    <img src={missao} alt='img missao' className='zoom-on-hover'/>
                </div>

                <div className='img-visao'>
                    <img src={visao} alt='img visao' className='zoom-on-hover' />
                </div>



            </div>

            <div className="servicos">
                <div>
                    <img src={imgServicos} className='img-title2 img-title2-centralizado' />
                </div>
    <div className="row">
        <div><img src={servicos1} className='servicos-box zoom-on-hover'/></div>
        <div><img src={servicos2} className='servicos-box zoom-on-hover'/></div>
        <div><img src={servicos3} className='servicos-box zoom-on-hover'/></div>
    </div>
    <div className="row2">
        <div><img src={servicos4} className='servicos-box2 zoom-on-hover img-2'/></div>
        <div><img src={servicos5} className='servicos-box2 zoom-on-hover'/></div>
    </div>
</div>


     
            <div className='terceirizar'>
    <div>
        <img src={terceirizar} alt='Título Terceirizar' className='img-terc img-title2-centralizado' />
    </div>
    <div className='conteudo'>
        <div className='coluna-esquerda'>
            <div className='quadro'>
                <h1>REDUÇÃO DE ERROS</h1>
                <p>Análise dos procedimentos realizados e conferência do correto preenchimento das guias. 
Reduzindo, assim, o índice de glosas.</p>
            </div>
            <div className='quadro'>
                <h1>EFICIÊNCIA ADMINISTRATIVA</h1>
                <p>Ter uma equipe dedicada ao faturamento permite que o profissional se concentre em sua prática clínica, em vez de gastar energia e tempo com questões administrativas complexas.

                </p>
            </div>
            <div className='quadro'>
                <h1>MELHORIA DO FLUXO DE CAIXA</h1>
                <p>Uma equipe competente tem o poder de acelerar o ciclo de faturamento e reduzir o tempo de recebimento dos serviços prestados, melhorando o fluxo de caixa da clínica.

                </p>
            </div>
                       <div className='quadro'>
                <h1>FOCO NO QUE IMPORTA</h1>
                <p>Com a terceirização do faturamento, 
os profissionais de saúde podem se concentrar mais no atendimento ao paciente, garantindo uma experiência melhor e mais focada no cuidado médico.
</p>
            </div>
        </div>
        <div className='coluna-direita'>
            <img src={imgTerceirizar} alt='Terceirizar' className='imagem-grande' />
 
        </div>
    </div>
</div>
    
<footer className="footer">
    <div className="footer-content">
        <div className="footer-left">
            <img src={footer} alt="Logo" />
        </div>
        <div className="footer-center">
            <div className="icon-text">
                <img src={localizacao} alt="Address Icon" />
                <p onClick={() => window.open('https://maps.app.goo.gl/koCS3msqMQPVxbcSA', '_blank')}>SEPS 709/909 Ed. FAPE sala 05A - Brasília/DF</p>
            </div>
            <div className="icon-text">
                <img src={email} alt="Email Icon" />
                <p onClick={() => window.location.href = "mailto:contato@lotusfaturamento.com.br"}>contato@lotusfaturamento.com.br</p>
            </div>
            <div className="icon-text">
                <img src={telefone} alt="Phone Icon" />
                <p onClick={() => window.open('https://wa.me/5561993754523', '_blank')}> (61) 99375-4523</p>
            </div>
            <div className="icon-text">
                <img src={insta} alt="Instagram Icon" />
                <p onClick={() => window.open('https://www.instagram.com/lotusfaturamento/', '_blank')}>@lotusfaturamento</p>
            </div>
        </div>
    </div>
</footer>



    <div className="imagem-flutuante">
    <a href="http://api.whatsapp.com/send?phone=5561993754523" target="_blank" rel="noopener noreferrer">
      <img src={whatsapp} alt="Descrição da imagem" />
    </a>
  </div>

    </div>
    );
}
